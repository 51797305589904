import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.paymentMethodsapi.scopes.read);
  return http.get('/payment-methods', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.paymentMethodsapi.scopes.read);
  return http.get(`/payment-methods/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.paymentMethodsapi.scopes.read);
  return http.post('/payment-methods', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.paymentMethodsapi.scopes.read);
  return http.patch(`/payment-methods/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deletePaymentMethod = async (id) => {
  const accessToken = await getToken(protectedResources.paymentMethodsapi.scopes.read);

  return http.patch(
    `/payment-methods/${id}`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const PaymentMethodService = {
  getAll,
  get,
  create,
  update,
  deletePaymentMethod
};

export default PaymentMethodService;
