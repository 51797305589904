import { devCycleSDKKey } from '@myfrey/config';
import { accountInfoToDVC, authProvider } from '@myfrey/auth';
import { initializeDevCycle } from '@devcycle/devcycle-js-sdk';

//Usage only outside of React
export const getFeatureFlagValue = async (FFname: string, FFDefaultValue: any) => {
  const account = authProvider.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const dvcClient = initializeDevCycle(devCycleSDKKey, accountInfoToDVC(account));
  await dvcClient.onClientInitialized();
  const FFValue = dvcClient.variableValue(FFname, FFDefaultValue);
  return FFValue;
};
