import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.contractDocumentsapi.scopes.read);
  return http.get('/contract-documents', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.contractDocumentsapi.scopes.read);
  return http.get(`/contract-documents/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.contractDocumentsapi.scopes.read);
  return http.post('/contract-documents', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.contractDocumentsapi.scopes.read);
  return http.patch(`/contract-documents/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteContractDocument = async (id) => {
  const accessToken = await getToken(protectedResources.contractDocumentsapi.scopes.read);
  return http.patch(
    `/contract-documents/${id}/soft-delete`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const ContractDocumentService = {
  getAll,
  get,
  create,
  update,
  deleteContractDocument
};

export default ContractDocumentService;
