import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '@myfrey/config';
import { useAuthProvider } from '../../hooks/useUserAuth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuthProvider();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(config.defaultPath, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
