import React, { ComponentType, PropsWithChildren, createContext } from 'react';
import { AuthProvider, AuthProviderState } from './AuthProvider';
import { msalAuthProvider } from './msalAuthProvider';
import { mockAuthProvider } from './mockAuthProvider';
import { env } from '@myfrey/config';

export const AuthContext = createContext<AuthProviderState>({
  isAdmin: false,
  isVerifying: true,
  isAuthenticating: true,
  isAuthenticated: false,
  getAccountInfo: () => null,
  getActiveAccount: () => null,
  loginRedirect: async () => {},
  logoutRedirect: async () => {}
});

export const authProvider: AuthProvider =
  env.REACT_APP_ENVIRONMENT === 'playwright' || env.REACT_APP_ENVIRONMENT === 'test' ? mockAuthProvider : msalAuthProvider;

export const AuthContextProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const authProviderState = authProvider.useAuthProvider();
  return <AuthContext.Provider value={authProviderState}>{children}</AuthContext.Provider>;
};
