import { createSlice } from '@reduxjs/toolkit';
import { openSnackbar as openSnackbarV2, closeSnackbar as closeSnackbarV2 } from '@myfrey-components/snackbar';
import type { SnackbarState } from '@myfrey-components/snackbar';

// ==============================|| SLICE - SNACKBAR ||============================== //

/**
 * it's here only for backwards compatibility with calling openSnackbar as a redux action. To be removed.
 */

const snackbar = createSlice({
  name: 'snackbar',
  initialState: {},
  reducers: {
    openSnackbar(_state, _action: { type: string; payload: Partial<SnackbarState> }) {}
  }
});

/** @deprecated - use openSnackbar from '@myfrey-components/snackbar' instead, without dispatching it as a redux action */
export function openSnackbar(payload: Partial<SnackbarState>) {
  openSnackbarV2(payload);
  return snackbar.actions.openSnackbar(payload);
}

export default snackbar.reducer;
