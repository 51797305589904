import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.contractlogisticsServiceProviderapi.scopes.read);
  return http.get('/contract-logistics-service-providers', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.contractlogisticsServiceProviderapi.scopes.read);
  return http.get(`/contract-logistics-service-providers/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.contractlogisticsServiceProviderapi.scopes.read);
  return http.post('/contract-logistics-service-providers', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.contractlogisticsServiceProviderapi.scopes.read);
  return http.patch(`/contract-logistics-service-providers/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteContractLogisticsProvider = async (id) => {
  const accessToken = await getToken(protectedResources.contractlogisticsServiceProviderapi.scopes.read);
  return http.patch(
    `/contract-logistics-service-providers/${id}/soft-delete`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const ContractLogisticsProviderService = {
  getAll,
  get,
  create,
  update,
  deleteContractLogisticsProvider
};

export default ContractLogisticsProviderService;
