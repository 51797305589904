import { Box, Typography } from '@mui/material';
import { useIsAdmin } from '../../../../../hooks/useUserAuth';
import menuItem from '../../../../../navigation/menu-items';
import NavGroup from './NavGroup';
import { useVariableValue } from '@devcycle/react-client-sdk';

const Navigation = () => {
  const { isAdmin } = useIsAdmin();

  const featureFlags = {
    twinTrade: useVariableValue('twin-trade-page', false)
  };

  const filteredMenuItems = menuItem.items.filter((item) => {
    const itemFeatureFlag = featureFlags[item.id];
    // Hide menu item if it's marked as admin-only and the user is not an admin
    if (item.isAdminOnly && !isAdmin) {
      return false;
    }

    // Hide menu item if it's marked as hidden
    if (item.hidden) {
      return false;
    }

    // Filter by feature flag
    if (typeof itemFeatureFlag !== 'undefined' && !itemFeatureFlag) return false;

    // Otherwise, show the menu item
    return true;
  });

  const navGroups = filteredMenuItems.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} featureFlags={featureFlags} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box sx={{ pt: 1 }} data-testid="menu">
      {navGroups}
    </Box>
  );
};

export default Navigation;
