import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.deliverytermsapi.scopes.read);
  return http.get('/delivery-terms', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.deliverytermsapi.scopes.read);
  return http.get(`/delivery-terms/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const DeliveryTermService = {
  getAll,
  get
};

export default DeliveryTermService;
