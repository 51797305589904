import { useIsDevCycleInitialized, useVariableValue } from '@devcycle/react-client-sdk';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useUpdateDVCUser } from '../../featureFlags/useFeatureFlags';

export const MaintenanceWindowBanner: React.FunctionComponent<{ children: React.ReactElement }> = ({ children }) => {
  useUpdateDVCUser();
  const isLoaded = useIsDevCycleInitialized();
  const isMaintenanceWindowActive = useVariableValue('maintenance-window', false);
  const maintenanceWindowDescription = useVariableValue('maintenance-window-description', '');

  if (!isLoaded)
    return (
      <Box
        data-testid="maintenance-window"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100vh'
        }}
      >
        <CircularProgress color="info" />
      </Box>
    );

  if (!isLoaded || isMaintenanceWindowActive) {
    return (
      <Box
        data-testid="maintenance-window"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100vh',
          backgroundImage: `url('/assets/images/landing/Topbillede_02.jpg')`,

          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 10%'
        }}
      >
        {isLoaded && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              flexDirection: 'column',
              maxWidth: '40%'
            }}
          >
            <Typography variant="h3" gutterBottom color={'white'}>
              Active maintenance window
            </Typography>
            {maintenanceWindowDescription && maintenanceWindowDescription.length > 0 && (
              <Typography fontSize={'1.1rem'} gutterBottom color={'white'}>
                {maintenanceWindowDescription.split('\\n').map((text, index) => (
                  <p key={index}>{text}</p>
                ))}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  }
  return children;
};
