import http from '../utils/http-common';

import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.documenttypesapi.scopes.read);

  return http.get('/document-types', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.documenttypesapi.scopes.read);

  return http.get(`/document-types/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.documenttypesapi.scopes.read);

  return http.post('/document-types', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.documenttypesapi.scopes.read);

  return http.patch(`/document-types/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteDocumentType = async (id) => {
  const accessToken = await getToken(protectedResources.documenttypesapi.scopes.read);

  return http.patch(
    `/document-types/${id}`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const DocumentTypeService = {
  getAll,
  get,
  create,
  update,
  deleteDocumentType
};

export default DocumentTypeService;
