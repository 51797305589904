import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

type Reference = {
  id: number;
  referenceName: string;
  referenceShortName: string;
  referenceType: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
};

const getAll = async () => {
  const accessToken = await getToken(protectedResources.referencesapi.scopes.read);
  return http.get<Reference[]>('/references', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id: string | number) => {
  const accessToken = await getToken(protectedResources.referencesapi.scopes.read);
  return http.get<Reference>(`/references/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const ReferenceService = {
  getAll,
  get
};

export default ReferenceService;
