import React, { useState, useCallback, useEffect } from 'react';
import merge from 'lodash/merge';
import MuiSnackbar from '@mui/material/Snackbar';
import { Alert, Button, Fade, Grow, GrowProps, Slide, SlideProps } from '@mui/material';

import IconButton from '@myfrey-components/icon-button';
import { CloseOutlined } from '@ant-design/icons';

type TransitionProps = Omit<SlideProps, 'direction'>;

// animation function
function TransitionSlideLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: TransitionProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: GrowProps) {
  return <Grow {...props} />;
}

// animation options
const animation = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

type SnackbarProps = Parameters<typeof MuiSnackbar>[0];
type AlertProps = Parameters<typeof Alert>[0];

type SharedSnackbarState = {
  action: boolean;
  open: boolean;
  message: string;
  anchorOrigin: SnackbarProps['anchorOrigin'];
  transition: 'Fade' | 'Grow' | 'SlideDown' | 'SlideRight' | 'SlideUp' | 'SlideLeft';
  close: boolean;
  actionButton: boolean | { text: string; url: string };
  timeout: number;
} & Pick<SnackbarProps, 'anchorOrigin'>;

type SnackbarStateDefault = {
  variant: 'default';
  alert?: undefined;
} & SharedSnackbarState;

type SnackbarStateAlert = {
  variant: 'alert';
  alert: Pick<AlertProps, 'color' | 'variant' | 'severity'>;
} & SharedSnackbarState;

export type SnackbarState = SnackbarStateAlert | SnackbarStateDefault;

const defaultAlert = {
  color: 'primary',
  variant: 'filled',
  severity: 'success'
};
const initialState: SnackbarState = {
  action: false,
  open: false,
  message: 'Note archived',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  variant: 'default',
  transition: 'Fade',
  close: true,
  actionButton: false,
  timeout: 6000
};

function placeholder() {
  throw new Error(`Snackbar component must be mounted before openSnackbar/closeSnackbar can be called`);
}

const controller = {
  openSnackbar: placeholder as (payload: Partial<SnackbarState>) => void,
  closeSnackbar: placeholder
};

// ==============================|| SNACKBAR ||============================== //

export const Snackbar = () => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>(initialState);

  const openSnackbar = useCallback((payload: Partial<SnackbarState>) => {
    setSnackbarState((prevState) =>
      merge(
        { ...initialState },
        {
          action: !prevState.action,
          ...payload,
          alert: (payload.variant === 'alert' ? payload.alert : undefined) ?? defaultAlert
        }
      )
    );
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  }, []);

  useEffect(() => {
    controller.openSnackbar = openSnackbar;
    controller.closeSnackbar = closeSnackbar;
    return () => {
      controller.openSnackbar = placeholder;
      controller.closeSnackbar = placeholder;
    };
  }, [openSnackbar, closeSnackbar]);

  const { actionButton, anchorOrigin, alert, close, message, open, transition, variant, timeout } = snackbarState;

  const handleClose = (_: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    closeSnackbar();
  };

  return (
    <>
      {/* default snackbar */}
      {variant === 'default' && (
        <MuiSnackbar
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={timeout}
          onClose={handleClose}
          message={message}
          TransitionComponent={animation[transition]}
          action={
            <>
              <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                <CloseOutlined />
              </IconButton>
            </>
          }
          data-testid="snackbar"
        />
      )}

      {/* alert snackbar */}
      {variant === 'alert' && (
        <MuiSnackbar
          TransitionComponent={animation[transition]}
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={timeout}
          onClose={handleClose}
          data-testid="snackbar"
        >
          <Alert
            variant={alert.variant}
            color={alert.color}
            severity={alert.severity}
            action={
              <>
                {actionButton !== false && typeof actionButton === 'boolean' && (
                  <Button color={alert.color} size="small" onClick={handleClose}>
                    UNDO
                  </Button>
                )}
                {actionButton !== false && typeof actionButton === 'object' && (
                  <Button size="small" href={actionButton.url}>
                    {actionButton.text}
                  </Button>
                )}
                {close !== false && (
                  <IconButton
                    sx={{ mt: 0.25 }}
                    size="small"
                    aria-label="close"
                    variant="contained"
                    color={alert.color}
                    onClick={handleClose}
                  >
                    <CloseOutlined />
                  </IconButton>
                )}
              </>
            }
            sx={{
              ...(alert.variant === 'outlined' && {
                bgcolor: 'grey.0'
              }),
              whiteSpace: 'pre-line'
            }}
          >
            {message}
          </Alert>
        </MuiSnackbar>
      )}
    </>
  );
};

export const openSnackbar = (payload: Partial<SnackbarState>) => controller.openSnackbar(payload);
export const closeSnackbar = () => controller.closeSnackbar();
