import { ShipmentCreate, ShipmentUpdate, Shipment, CreateSplitShipmentRequest, DiscountDB } from '@myfrey/shipments-types';
import { DeepPartial } from 'utility-types';
import { DocumentTypes } from '@myfrey/document-types/src';
import http from '../utils/http-common';

import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';
import { openSnackbar } from '../store/reducers/snackbar';
import { AxiosResponse } from 'axios';
import type { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents';

const v2Url = '/v2';

const getAllShipments = async () => {
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
  return http.get(v2Url + '/shipments', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id: string) => {
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
  return http.get(v2Url + `/shipments/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const getMeta = async (id: string): Promise<AxiosResponse<{ updatedAt: string; createdAt: string; id: string }>> => {
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
  return http.get(v2Url + `/shipments/${id}/meta`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const addShipment = async (data: DeepPartial<ShipmentCreate>, overallocate: boolean, headers: Record<string, string> = {}) => {
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
  const overallocateHeader: { Overallocate?: boolean } = overallocate ? { Overallocate: true } : {};
  return http.post(v2Url + '/shipments', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...overallocateHeader,
      ...headers
    }
  });
};

const updateShipment = async (
  id: string,
  data: DeepPartial<ShipmentUpdate>,
  overallocate: boolean,
  discount?: DiscountDB[],
  closedCostTypes?: string[],
  headers: Record<string, string> = {}
) => {
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
  const overallocateHeader: { Overallocate?: boolean } = overallocate ? { Overallocate: true } : {};
  console.log({ ...data, documentInstruction: { ...data.documentInstruction, ...(discount ? discount : []) } });
  return http.patch(
    `${v2Url}/shipments/${id}`,
    { ...data, documentInstruction: { ...data.documentInstruction, ...(discount ? { discount: discount } : []) }, closedCostTypes },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...overallocateHeader,
        ...headers
      }
    }
  );
};

const deleteShipment = async (id: string) => {
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
  return http.delete(v2Url + `/shipments/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const generateShipmentDocument = async (shipmentId: string, documentTypeIds: string[]) => {
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
  return http.post<string[], { data: { jobId: string }[] }>(v2Url + `/shipments/${shipmentId}/generate-documents`, documentTypeIds, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const generateShipmentDocuemntAndTrackStatus = (dispatch: any, createJob: any, shipmentId: string) =>
  async function (
    documentTypeId: string,
    {
      onTimeout,
      onOk,
      onError
    }: {
      onTimeout?: () => void;
      onOk?: () => void;
      onError?: () => void;
    } = {}
  ) {
    const documentType = DocumentTypes.find((type) => type.id === documentTypeId)?.name || 'Unknown document type';
    let jobId: string;
    try {
      const {
        data: [{ jobId: newJobId }]
      } = await ShipmentService.generateShipmentDocument(shipmentId, [documentTypeId]);
      jobId = newJobId;
    } catch {
      dispatch(
        openSnackbar({
          open: true,
          message: `Failed to generate ${documentType}`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      onError?.();
      return;
    }
    createJob({
      kind: 'ShipmentDocumentStatusPollJob',
      documentType,
      shipmentId,
      jobId,
      onTimeout,
      onOk,
      onError
    });
  };

const getGenerateShipmentDocumentStatus = async (shipmentId: string, jobId: string) => {
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
  return http.get(v2Url + `/shipments/${shipmentId}/generate-document-status/${jobId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const getAvailableDocumentTypes = async () => {
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
  return http.get<string[]>(v2Url + `/shipments/available-document-types`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const search = async (params?: SearchParams) => {
  const defaultParams = {
    q: '*'
  };

  const preparedParams = Object.assign({}, defaultParams, params);
  const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);

  return http.get<SearchResponse<Shipment>>(v2Url + `/shipments/search`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: preparedParams
  });
};

const createSplitShipmentJobAndTrackStatus = (
  id: string,
  data: DeepPartial<CreateSplitShipmentRequest>,
  dispatch: any,
  createJob: any,
  shipmentReference: string,
  bookingReference: string
) =>
  async function ({
    onTimeout,
    onOk,
    onError
  }: {
    onTimeout?: () => void;
    onOk?: () => void;
    onError?: () => void;
  } = {}) {
    let jobId: string;
    const accessToken = await getToken(protectedResources.shipmentsapi.scopes.read);
    try {
      const response = await http.post(v2Url + `/shipments/${id}/split-shipment-job`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      jobId = response.data.jobId;
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Failed to create split shipment`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      onError?.();
      throw error;
    }
    createJob({
      kind: 'SplitShipmentStatusPollJob',
      shipmentId: id,
      shipmentReference,
      bookingReference,
      jobId,
      onTimeout,
      onOk,
      onError
    });
  };

const getCreateSplitShipmentJobStatus = async (shipmentId: string, jobId: string) => {
  const accessToken = await getToken(protectedResources.api.scopes.read);

  return http.get(`${v2Url}/shipments/${shipmentId}/split-shipment-job/${jobId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const getSplitShipmentJobDetails = async (shipmentId: string, jobId: string) => {
  const accessToken = await getToken(protectedResources.api.scopes.read);

  return http.get(`${v2Url}/shipments/${shipmentId}/split-shipment-job/${jobId}/details`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const ShipmentService = {
  getAllShipments,
  get,
  getMeta,
  addShipment,
  updateShipment,
  deleteShipment,
  getGenerateShipmentDocumentStatus,
  generateShipmentDocument,
  generateShipmentDocuemntAndTrackStatus,
  getAvailableDocumentTypes,
  search,
  createSplitShipmentJobAndTrackStatus,
  getCreateSplitShipmentJobStatus,
  getSplitShipmentJobDetails
};

export default ShipmentService;
