import { datadogRum } from '@datadog/browser-rum';
import { useDVCClient } from '@devcycle/react-client-sdk';
import { useEffect } from 'react';

import { useAuthProvider } from '../hooks/useUserAuth';
import useMarkerIo from '../hooks/MarkerIo';

const accountInfoToDVC = (account: { homeAccountId?: string; username?: string; name?: string }) => {
  return { user_id: account.username, email: account?.username, name: account.name };
};

export const useUpdateDVCUser = () => {
  const dvcClient = useDVCClient();
  const authProvider = useAuthProvider();
  const accountInfo = authProvider.getAccountInfo();
  const widget = useMarkerIo();
  try {
    if (accountInfo) {
      datadogRum.setUser({
        email: accountInfo?.username,
        id: accountInfo?.homeAccountId,
        name: accountInfo?.name
      });
      if (widget && accountInfo?.username && accountInfo?.name) {
        widget.setReporter({
          email: accountInfo?.username,
          fullName: accountInfo?.name
        });
      }
    }
    dvcClient.subscribe('variableEvaluated:*', (key, variable) => {
      // track all variable evaluations
      datadogRum.addFeatureFlagEvaluation(key, variable.value);
      if (widget) {
        widget.setCustomData({
          [`ff-${key}`]: variable.value.toString()
        });
      }
    });
  } catch (error) {
    console.error('Unexpected error setting up DataDog feature flags');
  }
  useEffect(() => {
    if (accountInfo) {
      dvcClient.identifyUser(accountInfoToDVC(accountInfo));
    }
  }, [accountInfo, accountInfo?.username]);
};
