import PropTypes from 'prop-types';

import { loginRequest } from '@myfrey/config';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { LogoutOutlined, LoginOutlined } from '@ant-design/icons';
import { Account, authProvider } from '@myfrey/auth';

import { useAuthProvider } from '../../../../../hooks/useUserAuth';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const LoginWrapper = authProvider.loginWrapper;
const LogoutWrapper = authProvider.logoutWrapper;

const ProfileTab = () => {
  const { getActiveAccount, loginRedirect, logoutRedirect } = useAuthProvider();

  let activeAccount = getActiveAccount();

  const handleLoginRedirect = () => {
    loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    logoutRedirect({
      account: getActiveAccount() as Account
    });
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }} title={activeAccount ? activeAccount.name : 'Unknown'}>
      <LogoutWrapper>
        <ListItemButton selected={false} onClick={handleLogoutRedirect}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText primary="  Sign out " />
        </ListItemButton>
      </LogoutWrapper>
      <LoginWrapper>
        <ListItemButton selected={false} onClick={handleLoginRedirect}>
          <ListItemIcon>
            <LoginOutlined />
          </ListItemIcon>
          <ListItemText primary=" Sign in " />
        </ListItemButton>
      </LoginWrapper>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
