import {
  AccountInfo,
  IPublicClientApplication,
  InteractionStatus,
  PublicClientApplication,
  type Configuration as MSALConfiguration
} from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import React, { useState, useEffect, useMemo } from 'react';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';

import { env, testConfig } from '@myfrey/config';
import { AuthProvider } from './AuthProvider';

const MsalConfig: MSALConfiguration = {
  auth: {
    clientId: env.REACT_APP_MSAL_CLIENT_ID,
    redirectUri: window.location.origin,
    authority: `https://login.microsoftonline.com/${env.REACT_APP_MSAL_AUTHORITY_ID}`,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true
  },
  cache: { cacheLocation: 'localStorage' },
  ...testConfig
};

export const msalPublicClient = new PublicClientApplication(MsalConfig);

const getToken = async (instance: IPublicClientApplication, account: AccountInfo) => {
  instance.setActiveAccount(account);
  const response = await instance.acquireTokenSilent({
    account: account,
    scopes: ['https://graph.microsoft.com/.default']
  });
  return response.accessToken;
};

const getIsAdmin = async (instance: IPublicClientApplication, account: AccountInfo | null) => {
  if (!account) return false;

  const accessToken = await getToken(instance, account);
  const client = Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    }
  });

  const data = await client.api('/me/transitiveMemberOf').version('beta').select('id').get();

  const groupIds = data.value.map((group: { id: string }) => group.id);

  // Check if the user is a member of the admin group
  const isAdmin = groupIds.includes('bd6c3ca3-e89f-4857-a902-e5f429b35684');

  return isAdmin;
};

const useAuthProvider = () => {
  const [state, setState] = useState({
    isAdmin: false,
    isVerifying: true
  });
  const { instance, inProgress, accounts } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const account = instance.getActiveAccount();

  useEffect(() => {
    getIsAdmin(instance, account).then((isAdmin) => {
      setState((old) => ({
        ...old,
        isAdmin,
        isVerifying: false
      }));
    });
  }, [instance, inProgress]);

  const isAuthenticating = useMemo(() => {
    return inProgress !== InteractionStatus.None || !account?.homeAccountId || state.isVerifying;
  }, [inProgress, account?.homeAccountId, state.isVerifying]);

  useEffect(() => {
    // Ensure activee account is selected
    if (accounts.length != 0) {
      const tempAccount = accounts[0];
      instance.setActiveAccount(tempAccount);
    }
  }, [accounts, instance]);

  const autoProviderState = useMemo(() => {
    return {
      ...state,
      isAuthenticating,
      isAuthenticated,
      getActiveAccount: () => instance.getActiveAccount(),
      getAccountInfo: () => account,
      loginRedirect: (...args: any[]) => instance.loginRedirect(...args),
      logoutRedirect: (...args: any[]) => instance.logoutRedirect(...args)
    };
  }, [state, isAuthenticating, account, instance, isAuthenticated]);

  return autoProviderState;
};

export const msalAuthProvider: AuthProvider = {
  useAuthProvider,
  rootRouterWrapper: ({ children }) => (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>{children}</MsalAuthenticationTemplate>
  ),
  rootApplicationWrapper: ({ children }) => <MsalProvider instance={msalPublicClient}>{children}</MsalProvider>,
  loginWrapper: ({ children }) => <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>,
  logoutWrapper: ({ children }) => <AuthenticatedTemplate>{children}</AuthenticatedTemplate>,
  getActiveAccount: () => msalPublicClient.getActiveAccount(),
  acquireTokenSilent: (...args) => msalPublicClient.acquireTokenSilent(...args)
};
