import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.contractPackingOptionsapi.scopes.read);
  return http.get('/contract-packing-options', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.contractPackingOptionsapi.scopes.read);
  return http.get(`/contract-packing-options/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.contractPackingOptionsapi.scopes.read);
  return http.post('/contract-packing-options', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.contractPackingOptionsapi.scopes.read);
  return http.patch(`/contract-packing-options/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteContractPackingOption = async (id) => {
  const accessToken = await getToken(protectedResources.contractPackingOptionsapi.scopes.read);
  return http.patch(
    `/contract-packing-options/${id}/soft-delete`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const ContractPackingOptionService = {
  getAll,
  get,
  create,
  update,
  deleteContractPackingOption
};

export default ContractPackingOptionService;
