import { protectedResources } from '@myfrey/config';
import http from '../utils/http-common';
import { getToken } from '@myfrey/auth';

const transformCreateCompanyData = async (data) => {
  return {
    name: data.companyName,
    freyId: data.freyId,
    vatNumber: data.vatNumber,
    addresses: data.tblDisplayedAddresses.map((addressLine) => {
      return {
        displayAddress: addressLine.addressLineText
      };
    })
  };
};

const transformCompanyFromV2toV1 = (company) => {
  return {
    ...company,
    isActive: true,
    companyId: company.id,
    companyName: company.name,
    tblDisplayedAddresses: company.addresses.map((address, index) => {
      return {
        ...address,
        addressLineNumber: index,
        isActive: true,
        addressLineText: address.displayAddress
      };
    })
  };
};

const getCompaniesUrl = async () => {
  return 'v2/companies';
};

const getAll = async () => {
  const accessToken = await getToken(protectedResources.campaniesapi.scopes.read);
  const baseCompanyApiUrl = await getCompaniesUrl();
  const response = await http.get(baseCompanyApiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return {
    ...response,
    data: response.data.map((company) => transformCompanyFromV2toV1(company))
  };
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.campaniesapi.scopes.read);
  const baseCompanyApiUrl = await getCompaniesUrl();
  const response = await http.get(`${baseCompanyApiUrl}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return {
    ...response,
    data: transformCompanyFromV2toV1(response.data)
  };
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.campaniesapi.scopes.read);
  const baseCompanyApiUrl = await getCompaniesUrl();
  const companyCreateBody = await transformCreateCompanyData(data);
  return http.post(baseCompanyApiUrl, companyCreateBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.campaniesapi.scopes.read);
  const baseCompanyApiUrl = await getCompaniesUrl();
  const companyUpdateBody = await transformCreateCompanyData(data);
  return http.patch(`${baseCompanyApiUrl}/${id}`, companyUpdateBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteCompany = async (id) => {
  const accessToken = await getToken(protectedResources.campaniesapi.scopes.read);
  const baseCompanyApiUrl = await getCompaniesUrl();
  return http.delete(`${baseCompanyApiUrl}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const CompanyService = {
  getAll,
  get,
  create,
  update,
  deleteCompany
};

export default CompanyService;
