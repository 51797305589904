import http from '../utils/http-common';

import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.contractapi.scopes.read);

  return http.get(`insights/contracts`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.contractapi.scopes.read);

  return http.get(`/insights/contracts/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const ContractService = {
  getAll,
  get
};

export default ContractService;
