import http from '../utils/http-common';

import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken();

  return http.get('/quality-specs', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken();

  return http.get(`/quality-specs/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken();

  return http.post('/quality-specs', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken();

  return http.patch(`/quality-specs/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteQualitySpec = async (id) => {
  const accessToken = await getToken();

  return http.patch(
    `/quality-specs/${id}`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const QualitySpecService = {
  getAll,
  get,
  create,
  update,
  deleteQualitySpec
};

export default QualitySpecService;
