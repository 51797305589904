import { env } from './env';

export { env } from './env';

export const drawerWidth = 260;

export const devCycleSDKKey = env.REACT_APP_DVC_CLIENT_ID;

export const testConfig = env.REACT_APP_IS_TEST
  ? {
      cache: {
        cacheLocation: 'localStorage'
      }
    }
  : {};

export const apiBaseUrl = env.REACT_APP_MY_FREY_API_BASE_URL;

export const datadogConfig = {
  applicationId: env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: env.REACT_APP_DATADOG_CLIENT_TOKEN,
  env: env.REACT_APP_ENVIRONMENT,
  version: env.REACT_APP_VERSION
};

export const protectedResources = {
  contractapi: {
    endpoint: `${apiBaseUrl}/api/insights/contracts`,
    scopes: {
      read: ['urn:api:frey-app-api/contracts.read']
    }
  },
  countryapi: {
    endpoint: `${apiBaseUrl}/countries`,
    scopes: {
      read: ['urn:api:frey-app-api/countries.read']
    }
  },
  productcategoryapi: {
    endpoint: `${apiBaseUrl}/product-categories`,
    scopes: {
      read: ['urn:api:frey-app-api/productcategories.read']
    }
  },
  producttypeapi: {
    endpoint: `${apiBaseUrl}/product-types`,
    scopes: {
      read: ['urn:api:frey-app-api/producttypes.read']
    }
  },
  productsapi: {
    endpoint: `${apiBaseUrl}/products`,
    scopes: {
      read: ['urn:api:frey-app-api/products.read']
    }
  },

  campaniesapi: {
    endpoint: `${apiBaseUrl}/companies`,
    scopes: {
      read: ['urn:api:frey-app-api/companies.read']
    }
  },
  documenttypesapi: {
    endpoint: `${apiBaseUrl}/document-types`,
    scopes: {
      read: ['urn:api:frey-app-api/documenttypes.read']
    }
  },
  traderapi: {
    endpoint: `${apiBaseUrl}/traders`,
    scopes: {
      read: ['urn:api:frey-app-api/trader.read']
    }
  },

  contractsnewapi: {
    endpoint: `${apiBaseUrl}/contractsnew`,
    scopes: {
      read: ['urn:api:frey-app-api/contractsnew.read']
    }
  },

  qualitycategoriesapi: {
    endpoint: `${apiBaseUrl}/quality-categories`,
    scopes: {
      read: ['urn:api:frey-app-api/qualitycategories.read']
    }
  },

  documentissuersapi: {
    endpoint: `${apiBaseUrl}/document-issuers`,
    scopes: {
      read: ['urn:api:frey-app-api/documentissuers.read']
    }
  },
  referencesapi: {
    endpoint: `${apiBaseUrl}/references`,
    scopes: {
      read: ['urn:api:frey-app-api/references.read']
    }
  },
  currenciesapi: {
    endpoint: `${apiBaseUrl}/currencies`,
    scopes: {
      read: ['urn:api:frey-app-api/currencies.read']
    }
  },
  deliverytermsapi: {
    endpoint: `${apiBaseUrl}/delivery-terms`,
    scopes: {
      read: ['urn:api:frey-app-api/deliveryterms.read']
    }
  },
  carriersapi: {
    endpoint: `${apiBaseUrl}/carriers`,
    scopes: {
      read: ['urn:api:frey-app-api/carriers.read']
    }
  },
  equipmentapi: {
    endpoint: `${apiBaseUrl}/equipment`,
    scopes: {
      read: ['urn:api:frey-app-api/equipment.read']
    }
  },
  contractDocumentsapi: {
    endpoint: `${apiBaseUrl}/contract-documents`,
    scopes: {
      read: ['urn:api:frey-app-api/contractdocuments.read']
    }
  },
  displayedAddressesapi: {
    endpoint: `${apiBaseUrl}/displayed-addresses`,
    scopes: {
      read: ['urn:api:frey-app-api/displayedAddressesRead']
    }
  },
  contractCounterpartiesapi: {
    endpoint: `${apiBaseUrl}/contract-counterparties`,
    scopes: {
      read: ['urn:api:frey-app-api/contractcounterparties.read']
    }
  },
  paymentMethodsapi: {
    endpoint: `${apiBaseUrl}/payment-methods`,
    scopes: {
      read: ['urn:api:frey-app-api/paymentmethods.read']
    }
  },
  contractProductsapi: {
    endpoint: `${apiBaseUrl}/contract-products`,
    scopes: {
      read: ['urn:api:frey-app-api/contractproducts.read']
    }
  },
  brokersapi: {
    endpoint: `${apiBaseUrl}/brokers`,
    scopes: {
      read: ['urn:api:frey-app-api/brokers.read']
    }
  },
  futuresExchangesapi: {
    endpoint: `${apiBaseUrl}/futures-exchanges`,
    scopes: {
      read: ['urn:api:frey-app-api/futuresexchanges.read']
    }
  },
  futuresProductsapi: {
    endpoint: `${apiBaseUrl}/futures-products`,
    scopes: {
      read: ['urn:api:frey-app-api/futuresproducts.read']
    }
  },
  futuresContractsapi: {
    endpoint: `${apiBaseUrl}/futures-contracts`,
    scopes: {
      read: ['urn:api:frey-app-api/futurescontracts.read']
    }
  },
  locationsapi: {
    endpoint: `${apiBaseUrl}/locations`,
    scopes: {
      read: ['urn:api:frey-app-api/locations.read']
    }
  },
  contractLocationOptionsapi: {
    endpoint: `${apiBaseUrl}/contract-location-options`,
    scopes: {
      read: ['urn:api:frey-app-api/contractlocationoptions.read']
    }
  },
  contractQualitySpecsapi: {
    endpoint: `${apiBaseUrl}/contract-quality-specs`,
    scopes: {
      read: ['urn:api:frey-app-api/contractqualityspecs.read']
    }
  },
  contractPaymentTermsapi: {
    endpoint: `${apiBaseUrl}/contract-payment-terms`,
    scopes: {
      read: ['urn:api:frey-app-api/contractpaymentterms.read']
    }
  },
  contractSpecialTermsapi: {
    endpoint: `${apiBaseUrl}/contract-special-terms`,
    scopes: {
      read: ['urn:api:frey-app-api/contractspecialterms.read']
    }
  },
  contractShipmentsapi: {
    endpoint: `${apiBaseUrl}/contract-shipments`,
    scopes: {
      read: ['urn:api:frey-app-api/contractshipments.read']
    }
  },
  shipmentPeriodsapi: {
    endpoint: `${apiBaseUrl}/shipment-periods`,
    scopes: {
      read: ['urn:api:frey-app-api/shipmentperiods.read']
    }
  },
  contractPackingOptionsapi: {
    endpoint: `${apiBaseUrl}/contract-packing-options`,
    scopes: {
      read: ['urn:api:frey-app-api/contractpackingoptions.read']
    }
  },
  packingWeightOptionsapi: {
    endpoint: `${apiBaseUrl}/packing-weight-options`,
    scopes: {
      read: ['urn:api:frey-app-api/packingweightoptions.read']
    }
  },
  contractCarrierapi: {
    endpoint: `${apiBaseUrl}/contract-carriers`,
    scopes: {
      read: ['urn:api:frey-app-api/contractcarriers.read']
    }
  },
  logisticsServiceProviderapi: {
    endpoint: `${apiBaseUrl}/logistics-service-providers`,
    scopes: {
      read: ['urn:api:frey-app-api/logisticsserviceProviders.read']
    }
  },
  contractlogisticsServiceProviderapi: {
    endpoint: `${apiBaseUrl}/contract-logistics-service-providers`,
    scopes: {
      read: ['urn:api:frey-app-api/contractlogisticsserviceProviders.read']
    }
  },
  containerfacilitiesapi: {
    endpoint: `${apiBaseUrl}/container-facilities`,
    scopes: {
      read: ['urn:api:frey-app-api/containerfacilities.read']
    }
  },
  bookingsapi: {
    endpoint: `${apiBaseUrl}/bookings`,
    scopes: {
      read: ['urn:api:frey-app-api/bookings.read']
    }
  },
  transportscheduleapi: {
    endpoint: `${apiBaseUrl}/transport-schedule`,
    scopes: {
      read: ['urn:api:frey-app-api/transportschedule.read']
    }
  },
  shipmentsapi: {
    endpoint: `${apiBaseUrl}/v2/shipments`,
    scopes: {
      read: ['urn:api:frey-app-api/shipments.read']
    }
  },
  api: {
    endpoint: `${apiBaseUrl}/containers`,
    scopes: {
      read: ['urn:api:frey-app-api/defaultapi.read']
    }
  }
};

export const loginRequest = {
  scopes: [
    ...protectedResources.contractapi.scopes.read,
    ...protectedResources.countryapi.scopes.read,
    ...protectedResources.productcategoryapi.scopes.read,
    ...protectedResources.producttypeapi.scopes.read,
    ...protectedResources.productsapi.scopes.read,
    ...protectedResources.campaniesapi.scopes.read,
    ...protectedResources.documenttypesapi.scopes.read,
    ...protectedResources.traderapi.scopes.read,
    ...protectedResources.contractsnewapi.scopes.read,
    ...protectedResources.containerfacilitiesapi.scopes.read,
    ...protectedResources.bookingsapi.scopes.read,
    ...protectedResources.shipmentsapi.scopes.read,
    ...protectedResources.api.scopes.read
  ]
};

// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/home' as const,
  fontFamily: `'Public Sans', sans-serif` as const,
  miniDrawer: false,
  container: true,
  mode: 'light' as const,
  presetColor: 'default' as const,
  themeDirection: 'ltr' as const
};

export default config;
