import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.contractCarrierapi.scopes.read);
  return http.get('/contract-carriers', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.contractCarrierapi.scopes.read);
  return http.get(`/contract-carriers/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.contractCarrierapi.scopes.read);
  return http.post('/contract-carriers', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.contractCarrierapi.scopes.read);
  return http.patch(`/contract-carriers/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteContractCarrier = async (id) => {
  const accessToken = await getToken(protectedResources.contractCarrierapi.scopes.read);
  return http.patch(
    `/contract-carriers/${id}/soft-delete`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const ContractCarrierService = {
  getAll,
  get,
  create,
  update,
  deleteContractCarrier
};

export default ContractCarrierService;
