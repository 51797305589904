import { ComponentType, PropsWithChildren, createContext, useEffect, useState } from 'react';
import markerSDK, { type MarkerSdk } from '@marker.io/browser';
import { useVariableValue } from '@devcycle/react-client-sdk';

export default function useMarkerIo() {
  const addMarkerIo = useVariableValue('show-marker-io', false);
  const markerIdProject = useVariableValue('marker-io-project', '66d6cbd7de6fa1af39cac713');
  const [widget, setWidget] = useState<MarkerSdk>();

  useEffect(() => {
    if (addMarkerIo) {
      markerSDK
        .loadWidget({
          project: markerIdProject
        })
        .then(setWidget);
    }
  }, [addMarkerIo]);

  return widget;
}
