import { devCycleSDKKey } from '@myfrey/config';
import { authProvider } from './AuthContext';
import { Account } from './AuthProvider';
import { initializeDevCycle } from '@devcycle/devcycle-js-sdk';

export * from './AuthContext';
export * from './AuthProvider';

export const accountInfoToDVC = (account: { homeAccountId?: string; username?: string; name?: string }) => {
  return { user_id: account.homeAccountId, email: account?.username, name: account.name };
};

export const getToken = async (scopes: string[]) => {
  const account = authProvider.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const dvcClient = initializeDevCycle(devCycleSDKKey, accountInfoToDVC(account));
  await dvcClient.onClientInitialized();

  const scopeWithDefaults = !scopes || scopes.length === 0 ? ['urn:api:frey-app-api/.default'] : scopes;
  const response = await authProvider.acquireTokenSilent({
    account: account as Account,
    scopes: scopeWithDefaults
  });
  return response.accessToken;
};
