import { datadogLogs } from '@datadog/browser-logs';
import axios, { AxiosError } from 'axios';

function createHttpClient(apiBaseUrl: string) {
  const instance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      'Content-type': 'application/json'
    }
  });

  instance.interceptors.request.use((axiosConfig) => {
    if (!axiosConfig.headers) axiosConfig.headers = {};
    axiosConfig.headers['Expect-Enhanced-Data'] = true;
    axiosConfig.headers['Access-Control-Allow-Headers'] = 'Expect-Enhanced-Data';
    return axiosConfig;
  });

  const handleAxiosError = (error: AxiosError) => {
    if (!error.config) {
      return Promise.reject(error);
    }

    const requestDetails = {
      url: error.config.url,
      method: error.config.method?.toUpperCase(),
      status: error.response?.status || 'N/A',
      response: error.response?.data || 'No response data',
      params: error.config.params || 'No params',
      payload: error.config.data || 'No payload'
    };

    const errorMessage = `NetworkError - ${requestDetails.method} ${requestDetails.url} | Status: ${requestDetails.status}`;

    datadogLogs.logger.error(errorMessage, requestDetails);
    error.message = errorMessage;
    return Promise.reject(error);
  };

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      return handleAxiosError(error);
    }
  );

  return instance;
}

export default createHttpClient;
