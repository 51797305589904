import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { Box, Container, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import Breadcrumbs from '../../components/@extended/Breadcrumbs';
import { authProvider } from '@myfrey/auth';
import { useUpdateDVCUser } from '../../featureFlags/useFeatureFlags';
import useConfig from '../../hooks/useConfig';
import navigation from '../../navigation/menu-items';
import { crumbsConfigObject } from '../../navigation/crumbs';
import { openDrawer } from '../../store/reducers/menu';
import Drawer from './Drawer';
import Header from './Header';
import BreadcrumbsRefactored from '@myfrey-components/page-header';
import { MaintenanceWindowBanner } from '../../components/maintenance-window-banner';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  useUpdateDVCUser();
  //const activeAccount = instance.getActiveAccount();

  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();

  const menu = useSelector<{ menu: { drawerOpen: boolean } }, { drawerOpen: boolean }>((state) => state.menu);
  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const content = (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        {container && (
          <Container
            maxWidth={false}
            sx={{
              px: { xs: 0, sm: 2 },
              position: 'relative',
              minHeight: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {/* @ts-ignore */}
            <BreadcrumbsRefactored navigation={crumbsConfigObject} titleBottom card={false} divider={false} />
            <Outlet />
          </Container>
        )}
        {!container && (
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
            {/* @ts-ignore */}
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            <Outlet />
          </Box>
        )}
      </Box>
    </Box>
  );

  if (authProvider.rootRouterWrapper) {
    const Wrapper = authProvider.rootRouterWrapper;
    return (
      <Wrapper>
        <MaintenanceWindowBanner>{content}</MaintenanceWindowBanner>
      </Wrapper>
    );
  }

  return <MaintenanceWindowBanner>{content}</MaintenanceWindowBanner>;
};

export default MainLayout;
