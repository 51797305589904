import { protectedResources } from '@myfrey/config';
import http from '../utils/http-common';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.displayedAddressesapi.scopes.read);
  return http.get('/displayed-addresses', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.displayedAddressesapi.scopes.read);
  return http.get(`/displayed-addresses/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.displayedAddressesapi.scopes.read);
  return http.post('/displayed-addresses', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  return Promise.resolve({
    data: {
      statusCode: 200
    }
  });
};

const DisplayedAddressService = {
  getAll,
  get,
  create,
  update
};

export default DisplayedAddressService;
