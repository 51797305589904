import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.contractShipmentsapi.scopes.read);
  return http.get('/contract-shipments', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.contractShipmentsapi.scopes.read);
  return http.get(`/contract-shipments/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.contractShipmentsapi.scopes.read);
  return http.post('/contract-shipments', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.contractShipmentsapi.scopes.read);
  return http.patch(`/contract-shipments/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteContractShipment = async (id) => {
  const accessToken = await getToken(protectedResources.contractShipmentsapi.scopes.read);
  return http.patch(
    `/contract-shipments/${id}/soft-delete`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const ContractShipmentService = {
  getAll,
  get,
  create,
  update,
  deleteContractShipment
};

export default ContractShipmentService;
