import { AuthContext } from '@myfrey/auth';

import { useContext } from 'react';

export const useIsAdmin = () => {
  const context = useContext(AuthContext);
  return { isAdmin: context.isAdmin, isVerifying: context.isVerifying, isAuthenticating: context.isAuthenticating };
};

export const useIsAuthenticating = () => {
  const context = useContext(AuthContext);
  return context.isAuthenticating;
};

export const useAuthProvider = () => {
  const context = useContext(AuthContext);
  return context;
};
