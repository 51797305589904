import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';
import type { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents';
import { SalesContract, V2 as SaleContractV2 } from '@myfrey/sale-contracts-types';
import { PurchaseContract, V2 as PurchaseContractV2 } from '@myfrey/purchase-contracts-types';
import Joi from 'joi';
import { ContractStatusList } from '@myfrey/sale-contracts-types';
import { ContractType } from '../types/contract-types';
import { AxiosResponse } from 'axios';

export type Contract = SalesContract | PurchaseContract;

export const createSchema = Joi.object({
  contractType: Joi.string().allow('Purchase', 'Sale').required(),
  contractDate: Joi.date().required(),
  trader: Joi.object({
    id: Joi.number().required()
  })
    .required()
    .unknown(true),
  externalContractReference: Joi.string().optional(),
  boteId: Joi.string().optional(),
  status: Joi.string()
    .allow(...ContractStatusList)
    .optional(),
  buyerCompany: Joi.object({
    id: Joi.string().required()
  })
    .unknown(true)
    .required(),
  sellerCompany: Joi.object({
    id: Joi.string().required()
  })
    .unknown(true)
    .required(),
  products: Joi.array()
    .required()
    .items(
      Joi.object({
        id: Joi.number().required()
      })
        .unknown(true)
        .required()
    ),
  productsOption: Joi.when('products', {
    is: Joi.array().min(2),
    then: Joi.string().required(),
    otherwise: Joi.forbidden()
  }),
  cropYear: Joi.string().optional()
});

const getAll = async () => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);

  return http.get('/contractsnew', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const getAllSalesOrPurchaseContracts = async (type: 'sales' | 'purchase') => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  const typeUrl = type === 'sales' ? 'salescontracts' : 'purchasecontracts';
  return http.get(`/contractsnew/${typeUrl}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const getAllSalesOrPurchaseContractsv2 = async (type: 'sale' | 'purchase', filterProperties = false) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  const typeUrl = type === 'sale' ? 'sale' : 'purchase';
  return http.get(`v2/contracts/${typeUrl}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...(filterProperties ? { 'Limit-Properties': 'true' } : {})
    }
  });
};

const getSaleContract = async (id: string | number) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get(`/contractsnew/${id}/salecontract`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const getPurchaseContract = async (id: string | number) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get(`/contractsnew/${id}/purchasecontract`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id: string | number) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get(`/contractsnew/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data: unknown) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.post('/contractsnew', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id: string | number, data: unknown) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.patch(`/contractsnew/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteContract = async (id: string | number) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.patch(
    `/contractsnew/${id}`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const finalize = async (id: string | number) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get(`/contractsnew/${id}/finalize`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const sendPDF = async (id: string | number) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get(`/contractsnew/${id}/sendPDF`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const search = async (params: SearchParams) => {
  const defaultParams = {
    q: '*'
  };

  const preparedParams = Object.assign({}, defaultParams, params);
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);

  return http.get<SearchResponse<Contract>>('/v2/contracts', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      UseSearch: 'true'
    },
    params: preparedParams
  });
};

const getContractAllocations = async (id: string | number, type: ContractType) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);

  return http.get<(PurchaseContractV2.PurchaseContract | SaleContractV2.SaleContract)['allocations']>(
    `/contracts-refactoring/${type}/${id}/allocations`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const ContractService = {
  getAll,
  getAllSalesOrPurchaseContracts,
  getAllSalesOrPurchaseContractsv2,
  getSaleContract,
  getPurchaseContract,
  get,
  create,
  update,
  deleteContract,
  finalize,
  sendPDF,
  search,
  getContractAllocations
};

export default ContractService;
