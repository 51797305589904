import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// scroll bar
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// project import
import App from './App';
import { store } from './store';
import { ConfigProvider } from './contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';
import { datadogConfig } from '@myfrey/config';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { authProvider } from '@myfrey/auth';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '7844729e-2ee7-42fa-9073-dd28a9e94a15',
    enableAutoRouteTracking: true
  }
});

appInsights.loadAppInsights();
(window as unknown as Record<string, string | unknown>).VERSION = datadogConfig.version;

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const content = (
  <ReduxProvider store={store}>
    <ConfigProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>
);

const RootApplicationAuthWrapper = authProvider.rootApplicationWrapper;

ReactDOM.render(
  <React.StrictMode>
    {RootApplicationAuthWrapper ? <RootApplicationAuthWrapper>{content}</RootApplicationAuthWrapper> : content}
  </React.StrictMode>,
  document.getElementById('root')
);

var style = document.createElement('style');

style.appendChild(
  document.createTextNode(`iframe {
    pointer-events: none;
  }`)
);

document.getElementsByTagName('head')[0].appendChild(style);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
