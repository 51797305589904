import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.futuresExchangesapi.scopes.read);
  return http.get('/futures-exchanges', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.futuresExchangesapi.scopes.read);
  return http.get(`/futures-exchanges/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const FuturesExchangeService = {
  getAll,
  get
};

export default FuturesExchangeService;
