import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { getToken } from '@myfrey/auth';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.futuresProductsapi.scopes.read);
  return http.get('/futures-products', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.futuresProductsapi.scopes.read);
  return http.get(`/futures-products/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const FuturesProductService = {
  getAll,
  get
};

export default FuturesProductService;
