import http from '../utils/http-common';
import { protectedResources } from '@myfrey/config';
import { OceanBookingCreate, OceanBookingUpdate, OceanBookingSearchApiItem } from '@myfrey/bookings-types';
import { getToken } from '@myfrey/auth';
import type { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents';

const getAllBookings = async (getWeights = false) => {
  const accessToken = await getToken(protectedResources.bookingsapi.scopes.read);
  return http.get('/bookings', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...(getWeights ? { 'Get-Container-Weight': 'true' } : {})
    }
  });
};

const getBooking = async (id: string) => {
  const accessToken = await getToken(protectedResources.bookingsapi.scopes.read);
  return http.get(`/bookings/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const addBooking = async (data: OceanBookingCreate, overwrite: boolean = false) => {
  const accessToken = await getToken(protectedResources.bookingsapi.scopes.read);
  return http.post('/bookings', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      overwrite: overwrite
    }
  });
};

const updateBooking = async (id: string, data: OceanBookingUpdate) => {
  const accessToken = await getToken(protectedResources.bookingsapi.scopes.read);
  return http.put(`/bookings/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const markContainersComplete = async (
  id: string,
  containersCompleted: boolean,
  contractsWeights?: { contractId: number | string; totalWeight: number }[]
) => {
  const accessToken = await getToken(protectedResources.bookingsapi.scopes.read);
  return http.post(
    `/bookings/${id}/containers-complete`,
    { containersCompleted, contractsWeights },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const search = async (params?: SearchParams) => {
  const defaultParams = {
    q: '*'
  };

  const preparedParams = Object.assign({}, defaultParams, params);
  const accessToken = await getToken(protectedResources.traderapi.scopes.read);

  return http.get<SearchResponse<OceanBookingSearchApiItem>>('/bookings/search', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: preparedParams
  });
};

const deleteBooking = async (id: number) => {
  return id;
};

const markShippingInstructionsSubmitted = async (id: string, isShippingInstructionsSubmitted: boolean) => {
  const accessToken = await getToken(protectedResources.bookingsapi.scopes.read);
  return http.post(
    `/bookings/${id}/si-submitted`,
    { isShippingInstructionsSubmitted },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const BookingService = {
  getAllBookings,
  getBooking,
  addBooking,
  updateBooking,
  deleteBooking,
  markContainersComplete,
  search,
  markShippingInstructionsSubmitted
};

export default BookingService;
